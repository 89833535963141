.tronWalletGuide {
    margin: 0;
    border-radius: 4px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
    padding: 40px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    background-color: #2e2f47;
    text-align: center;

    h1 {
        margin: 0;
        margin-bottom: 10px;
        text-align: center;
        font-size: 21px;
    }

    p {
        padding: 0;
        margin: 0;
    }

    .info {
        
    }

    .logo {
        margin-left: 0px;
        margin-bottom: 20px;
    }

    .download-call{
        text-align: center;
        margin-top: 20px;
    }
    
        .download-call p{
            font-size: 11px;  
        }
    
    .btn-download{
        -webkit-font-smoothing: antialiased;
        -webkit-appearance: none;
        vertical-align: middle;
        cursor: pointer;
        color: white;
        background-color: white;
        background-image: linear-gradient(rgb(35, 194, 119), rgb(57, 157, 108));
        box-shadow: rgba(67, 90, 111, 0.3) 0px 0px 0px 1px inset, rgba(67, 90, 111, 0.06) 0px -1px 1px 0px inset;
        text-decoration: none;
        border-width: initial;
        border-style: none;
        border-color: initial;
        border-image: initial;
        outline: none;
        padding: 20px;
        margin: 6px 0;
        display: inline-block;
        border-radius: 5px;
        font-weight: 100;
        font-size: 20px;
    }
    
    .btn-download i{
        font-size: 15px;
        margin-right: 10px;
        background: #fff;
        border-radius: 50%;
        height: 25px;
        width: 25px;
        line-height: 24px;
        text-align: center;
        padding: 0;
        color: rgb(35, 194, 119);
        padding-top: 2px;
    }
    
    .btn-download:hover{
        color: #fff;
        text-decoration: none;
    }
}
